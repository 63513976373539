#wrapper {
    display: flex;
  }
  
  #wrapper #content-wrapper {
    background-color: #F1F6FA;
    width: 100%;
    overflow-x: hidden;
  }
  
  #wrapper #content-wrapper #content {
    flex: 1 0 auto;
  }
  
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: #fff;
    background: rgba(90, 92, 105, 0.5);
    line-height: 46px;
  }
  
  .scroll-to-top:focus,
  .scroll-to-top:hover {
    color: #fff;
  }
  
  .scroll-to-top:hover {
    background: #5a5c69;
  }
  
  .scroll-to-top i {
    font-weight: 800;
  }
  
  @-webkit-keyframes growIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes growIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .animated--grow-in,
  .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .animated--fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  }
  
  .bg-gradient-secondary {
    background-color: #858796;
    background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
    background-size: cover;
  }
  
  .bg-gradient-light {
    background-color: #fff;
    background-image: linear-gradient(180deg, #fff 10%, #fff 100%);
    background-size: cover;
  }
  
  .bg-gray-100 {
    background-color: #f8f9fc !important;
  }
  
  .bg-gray-200 {
    background-color: #eaecf4 !important;
  }
  
  .bg-gray-300 {
    background-color: #dddfeb !important;
  }
  
  .bg-gray-400 {
    background-color: #d1d3e2 !important;
  }
  
  .bg-gray-500 {
    background-color: #b7b9cc !important;
  }
  
  .bg-gray-600 {
    background-color: #858796 !important;
  }
  
  .bg-gray-700 {
    background-color: #6e707e !important;
  }
  
  .bg-gray-800 {
    background-color: #5a5c69 !important;
  }
  
  .bg-gray-900 {
    background-color: #3a3b45 !important;
  }
  
  .o-hidden {
    overflow: hidden !important;
  }
  
  .text-xs {
    font-size: 0.7rem;
  }
  
  .text-lg {
    font-size: 1.2rem;
  }
  
  .text-gray-100 {
    color: #f8f9fc !important;
  }
  
  .text-gray-200 {
    color: #eaecf4 !important;
  }
  
  .text-gray-300 {
    color: #dddfeb !important;
  }
  
  .text-gray-400 {
    color: #d1d3e2 !important;
  }
  
  .text-gray-500 {
    color: #b7b9cc !important;
  }
  
  .text-gray-600 {
    color: #858796 !important;
  }
  
  .text-gray-700 {
    color: #6e707e !important;
  }
  
  .text-gray-800 {
    color: #5a5c69 !important;
  }
  
  .text-gray-900 {
    color: #3a3b45 !important;
  }
  
  .icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .border-left-primary {
    border-left: 0.25rem solid #4e73df !important;
  }
  
  .border-bottom-primary {
    border-bottom: 0.25rem solid #4e73df !important;
  }
  
  .border-left-secondary {
    border-left: 0.25rem solid #858796 !important;
  }
  
  .border-bottom-secondary {
    border-bottom: 0.25rem solid #858796 !important;
  }
  
  .border-left-success {
    border-left: 0.25rem solid #1cc88a !important;
  }
  
  .border-bottom-success {
    border-bottom: 0.25rem solid #1cc88a !important;
  }
  
  .border-left-info {
    border-left: 0.25rem solid #36b9cc !important;
  }
  
  .border-bottom-info {
    border-bottom: 0.25rem solid #36b9cc !important;
  }
  
  .border-left-warning {
    border-left: 0.25rem solid #f6c23e !important;
  }
  
  .border-bottom-warning {
    border-bottom: 0.25rem solid #f6c23e !important;
  }
  
  .border-left-danger {
    border-left: 0.25rem solid #e74a3b !important;
  }
  
  .border-bottom-danger {
    border-bottom: 0.25rem solid #e74a3b !important;
  }
  
  .border-left-light {
    border-left: 0.25rem solid #f8f9fc !important;
  }
  
  .border-bottom-light {
    border-bottom: 0.25rem solid #f8f9fc !important;
  }
  
  .border-left-dark {
    border-left: 0.25rem solid #5a5c69 !important;
  }
  
  .border-bottom-dark {
    border-bottom: 0.25rem solid #5a5c69 !important;
  }
  
  .progress-sm {
    height: 0.5rem;
  }
  
  .rotate-15 {
    transform: rotate(15deg);
  }
  
  .rotate-n-15 {
    transform: rotate(-15deg);
  }
  
  .dropdown .dropdown-menu {
    font-size: 0.85rem;
  }
  
  .dropdown .dropdown-menu .dropdown-header {
    font-weight: 800;
    font-size: 0.65rem;
    color: #b7b9cc;
  }
  
  .dropdown.no-arrow .dropdown-toggle::after {
    display: none;
  }
  
  .sidebar .nav-item.dropdown .dropdown-toggle::after,
  .topbar .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
  }
  
  .sidebar .nav-item.dropdown.show .dropdown-toggle::after,
  .topbar .nav-item.dropdown.show .dropdown-toggle::after {
    content: "\f107";
  }
  
  .sidebar .nav-item .nav-link,
  .topbar .nav-item .nav-link {
    position: relative;
  }
  
  .sidebar .nav-item .nav-link .badge-counter,
  .topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    right: 0.25rem;
    margin-top: -0.25rem;
  }
  
  .sidebar .nav-item .nav-link .img-profile,
  .topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
  }
  
  .topbar {
    height: 4.375rem;
  }
  
  .topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .topbar #sidebarToggleTop:hover {
    background-color: #eaecf4;
  }
  
  .topbar #sidebarToggleTop:active {
    background-color: #dddfeb;
  }
  
  .topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
  }
  
  .topbar .nav-item .nav-link {
    height: 4.375rem;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
  }
  
  .topbar .nav-item .nav-link:focus {
    outline: 0;
  }
  
  .topbar .nav-item:focus {
    outline: 0;
  }
  
  .topbar .dropdown {
    position: static;
  }
  
  .topbar .dropdown .dropdown-menu {
    width: calc(100% - 1.5rem);
    right: 0.75rem;
  }
  
  .topbar .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden;
  }
  
  .topbar .dropdown-list .dropdown-header {
    background-color: #4e73df;
    border: 1px solid #4e73df;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff;
  }
  
  .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
  }
  
  .topbar .dropdown-list .dropdown-item .dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
    background-color: #eaecf4;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0.125rem solid #fff;
  }
  
  .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 10rem;
  }
  
  .topbar .dropdown-list .dropdown-item:active {
    background-color: #eaecf4;
    color: #3a3b45;
  }
  
  @media (min-width: 576px) {
    .topbar .dropdown {
      position: relative;
    }
  
    .topbar .dropdown .dropdown-menu {
      width: auto;
      right: 0;
    }
  
    .topbar .dropdown-list {
      width: 20rem !important;
    }
  
    .topbar .dropdown-list .dropdown-item .text-truncate {
      max-width: 13.375rem;
    }
  }
  
  .topbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
  }
  
  .topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
    color: #fff;
  }
  
  .topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: #b7b9cc;
  }
  
  .topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
    color: #858796;
  }
  
  .sidebar {
    width: 6.5rem;
    min-height: 100vh;
  }
  
  .sidebar .nav-item {
    position: relative;
  }
  
  .sidebar .nav-item:last-child {
    margin-bottom: 1rem;
  }
  
  .sidebar .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
  }
  
  .sidebar .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  
  .sidebar .nav-item.active .nav-link {
    font-weight: 700;
  }
  
  .sidebar .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
  }
  
  .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0.35rem;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  }
  
  .sidebar .nav-item .collapsing {
    display: none;
    transition: none;
  }
  
  .sidebar .nav-item .collapse .collapse-inner,
  .sidebar .nav-item .collapsing .collapse-inner {
    padding: 0.5rem 0;
    min-width: 10rem;
    font-size: 0.85rem;
    margin: 0 0 1rem 0;
  }
  
  .sidebar .nav-item .collapse .collapse-inner .collapse-header,
  .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.65rem;
    color: #b7b9cc;
  }
  
  .sidebar .nav-item .collapse .collapse-inner .collapse-item,
  .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: 0.35rem;
    white-space: nowrap;
  }
  
  .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
  .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
  }
  
  .sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
  .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
    background-color: #dddfeb;
  }
  
  .sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
  .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
    color: #4e73df;
    font-weight: 700;
  }
  
  .sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .sidebar #sidebarToggle::after {
    font-weight: 900;
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    margin-right: 0.1rem;
  }
  
  .sidebar #sidebarToggle:hover {
    text-decoration: none;
  }
  
  .sidebar #sidebarToggle:focus {
    outline: 0;
  }
  
  .sidebar.toggled {
    width: 0 !important;
    overflow: hidden;
  }
  
  .sidebar.toggled #sidebarToggle::after {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    margin-left: 0.25rem;
  }
  
  .sidebar.toggled .sidebar-card {
    display: none;
  }
  
  .sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
  }
  
  .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  
  .sidebar .sidebar-brand .sidebar-brand-text {
    display: none;
  }
  
  .sidebar hr.sidebar-divider {
    margin: 0 1rem 1rem;
  }
  
  .sidebar .sidebar-heading {
    text-align: center;
    padding: 0 1rem;
    font-weight: 800;
    font-size: 0.65rem;
  }
  
  @media (min-width: 768px) {
    .sidebar {
      width: 10rem !important;
    }
  
    .sidebar .nav-item .collapse {
      position: relative;
      left: 0;
      z-index: 1;
      top: 0;
      -webkit-animation: none;
      animation: none;
    }
  
    .sidebar .nav-item .collapse .collapse-inner {
      border-radius: 0;
      box-shadow: none;
    }
  
    .sidebar .nav-item .collapsing {
      display: block;
      transition: height 0.15s ease;
    }
  
    .sidebar .nav-item .collapse,
    .sidebar .nav-item .collapsing {
      margin: 0 1rem;
    }
  
    .sidebar .nav-item .nav-link {
      display: block;
      width: 100%;
      text-align: center;
      padding: 1rem;
    }
  
    .sidebar .nav-item .nav-link i {
      font-size: 24px;
    }
  
    .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
      width: 1rem;
      text-align: center;
      float: right;
      vertical-align: 0;
      border: 0;
      font-weight: 900;
      content: "\f107";
      font-family: "Font Awesome 5 Free";
    }
  
    .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
      content: "\f105";
    }
  
    .sidebar .sidebar-brand .sidebar-brand-icon i {
      font-size: 2rem;
    }
  
    .sidebar .sidebar-brand .sidebar-brand-text {
      display: inline;
    }
  
    .sidebar .sidebar-heading {
      text-align: left;
    }
  
    .sidebar.toggled {
      overflow: visible;
      width: 6.5rem !important;
    }
  
    .sidebar.toggled .nav-item .collapse {
      position: absolute;
      left: calc(6.5rem + 1.5rem / 2);
      z-index: 1;
      top: 2px;
      -webkit-animation-name: growIn;
      animation-name: growIn;
      -webkit-animation-duration: 0.2s;
      animation-duration: 0.2s;
      -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
        opacity cubic-bezier(0, 1, 0.4, 1);
      animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
        opacity cubic-bezier(0, 1, 0.4, 1);
    }
  
    .sidebar.toggled .nav-item .collapse .collapse-inner {
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
      border-radius: 0.35rem;
    }
  
    .sidebar.toggled .nav-item .collapsing {
      display: none;
      transition: none;
    }
  
    .sidebar.toggled .nav-item .collapse,
    .sidebar.toggled .nav-item .collapsing {
      margin: 0;
    }
  
    .sidebar.toggled .nav-item:last-child {
      margin-bottom: 1rem;
    }
  
    .sidebar.toggled .nav-item .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: 6.5rem;
    }
  
    .sidebar.toggled .nav-item .nav-link span {
      font-size: 0.65rem;
      display: block;
    }
  
    .sidebar.toggled .nav-item .nav-link i {
      margin-right: 0;
    }
  
    .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
      display: none;
    }
  
    .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
      font-size: 2rem;
    }
  
    .sidebar.toggled .sidebar-brand .sidebar-brand-text {
      display: none;
    }
  
    .sidebar.toggled .sidebar-heading {
      text-align: center;
    }
  }
  
  .sidebar-light .sidebar-brand {
    color: #6e707e;
  }
  
  .sidebar-light hr.sidebar-divider {
    border-top: 1px solid #eaecf4;
  }
  
  .sidebar-light .sidebar-heading {
    color: #b7b9cc;
  }
  
  .sidebar-light .nav-item .nav-link {
    color: #858796;
  }
  
  .sidebar-light .nav-item .nav-link i {
    color: #d1d3e2;
  }
  
  .sidebar-light .nav-item .nav-link:active,
  .sidebar-light .nav-item .nav-link:focus,
  .sidebar-light .nav-item .nav-link:hover {
    color: #6e707e;
  }
  
  .sidebar-light .nav-item .nav-link:active i,
  .sidebar-light .nav-item .nav-link:focus i,
  .sidebar-light .nav-item .nav-link:hover i {
    color: #6e707e;
  }
  
  .sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
    color: #b7b9cc;
  }
  
  .sidebar-light .nav-item.active .nav-link {
    color: #6e707e;
  }
  
  .sidebar-light .nav-item.active .nav-link i {
    color: #6e707e;
  }
  
  .sidebar-light #sidebarToggle {
    background-color: #eaecf4;
  }
  
  .sidebar-light #sidebarToggle::after {
    color: #b7b9cc;
  }
  
  .sidebar-light #sidebarToggle:hover {
    background-color: #dddfeb;
  }
  
  .sidebar-dark .sidebar-brand {
    color: #000;
  }
  
  .sidebar-dark .sidebar-heading {
    color: #000;
  }
  
  .sidebar-dark .nav-item .nav-link {
    color: #000;
  }
  
  .sidebar-dark .nav-item .nav-link i {
    color: #000;
  }
  
  .sidebar-dark .nav-item .nav-link:active,
  .sidebar-dark .nav-item .nav-link:focus,
  .sidebar-dark .nav-item .nav-link:hover {
    color: #F2C94C;
  }
  
  .sidebar-dark .nav-item .nav-link:active i,
  .sidebar-dark .nav-item .nav-link:focus i,
  .sidebar-dark .nav-item .nav-link:hover i {
    color: #F2C94C;
  }
  
  .sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .sidebar-dark .nav-item.active .nav-link {
    color: #F2C94C;
  }
  
  .sidebar-dark .nav-item.active .nav-link i {
    color: #F2C94C;
  }
  
  .sidebar-dark #sidebarToggle {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .sidebar-dark #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .sidebar-dark #sidebarToggle:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .sidebar-dark.toggled #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .btn-circle {
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-circle.btn-sm,
  .btn-group-sm>.btn-circle.btn {
    height: 1.8rem;
    width: 1.8rem;
    font-size: 0.75rem;
  }
  
  .btn-circle.btn-lg,
  .btn-group-lg>.btn-circle.btn {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.35rem;
  }
  
  .btn-icon-split {
    padding: 0;
    overflow: hidden;
    display: inline-flex;
    align-items: stretch;
    justify-content: center;
  }
  
  .btn-icon-split .icon {
    background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: 0.375rem 0.75rem;
  }
  
  .btn-icon-split .text {
    display: inline-block;
    padding: 0.375rem 0.75rem;
  }
  
  .btn-group-sm>.btn-icon-split.btn .icon,
  .btn-icon-split.btn-sm .icon {
    padding: 0.25rem 0.5rem;
  }
  
  .btn-group-sm>.btn-icon-split.btn .text,
  .btn-icon-split.btn-sm .text {
    padding: 0.25rem 0.5rem;
  }
  
  .btn-group-lg>.btn-icon-split.btn .icon,
  .btn-icon-split.btn-lg .icon {
    padding: 0.5rem 1rem;
  }
  
  .btn-group-lg>.btn-icon-split.btn .text,
  .btn-icon-split.btn-lg .text {
    padding: 0.5rem 1rem;
  }
  
  .card .card-header .dropdown {
    line-height: 1;
  }
  
  .card .card-header .dropdown .dropdown-menu {
    line-height: 1.5;
  }
  
  .card .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;
  }
  
  .card .card-header[data-toggle="collapse"]::after {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 1.725rem;
    line-height: 51px;
    font-weight: 900;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    color: #d1d3e2;
  }
  
  .card .card-header[data-toggle="collapse"].collapsed {
    border-radius: 0.35rem;
  }
  
  .card .card-header[data-toggle="collapse"].collapsed::after {
    content: "\f105";
  }