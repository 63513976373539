.iconBox {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border-width: 2px;
    border: 1px solid #E3EBF1;
  }
  
  .icon {
    margin-left: 30%;
    margin-top: 30%;
    font-size: 25px;
    color: #FF844B;
  }
  
  .size {
    color: #FF844B;
  }
  
  .box {
    width: 90%;
    height: 200px;
    background-color: #FF844B;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }
  
  .textStore {
    margin-top: 10%;
    font-size: 24px;
    margin-left: 5%;
    color: white;
  }
  
  .planBox {
    height: 50px;
    width: 60%;
    margin-left: 5%;
    /* margin-right: auto; */
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .textPlan {
    margin-left: 20%;
    margin-top: 5%;
    color: white;
    font-size: 20;
  }
  
  .textFile {
    margin-left: 30%;
    /* margin-right: auto; */
  }
  
  .textNum {
    font-size: 38px;
  }
  
  .upIcon {
    margin-left: 8%;
    margin-top: 12%;
    font-size: 35px;
    color: #38E25D;
  }
  
  .textPrcess {
    font-size: 22px;
  }
  
  .addBox {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    background-color: #C5E4FC;
  }
  
  .addNew {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10%;
    margin-top: -2%;
  }
  
  .number {
    font-size: 38px;
    color: #38E25D;
    font-weight: 700;
  }
  
  .page {
    font-size: 20px;
    font-weight: 600;
  
  }
  
  .page2 {
    font-size: 12px;
    font-weight: 600;
    color: #787878;
  }
  
  .Process {
    color: #787878;
    font-size: 14;
  }
  
  .viewBtn {
    height: 30px;
    width: 35%;
    background-color: #FFEBCC;
    border-radius: 20px;
    margin-left: 20%;
    /* margin-top: 4%; */
  }
  
  .view {
    margin-left: 24%;
    margin-top: 30%;
    font-size: 12px;
    color: #FFAB2D
  }
  
  .searchbox {
    height: 40px;
    width: 30%;
    background-color: white;
    border-radius: 20px;
    margin-left: 8%;
    margin-top: 2%;
    padding: 2%;
  }
  
  .circle {
    width: 21px;
    height: 21px;
    border-radius: 20px;
    background-color: #FFAB2D;
    margin-top: 3px;
  }
  
  .circle2 {
    width: 21px;
    height: 21px;
    border-radius: 20px;
    background-color: #38E25D;
    margin-top: 3px;
  }
  .heading{
    color:rgb(72, 72, 73)(156, 118, 118);
  }